import React from 'react';
import DealCard from './DealCard';

const DealsList = ({ deals }) => {
  return (
    <div className="container">
      <div className="deals-list">
        {deals.map(deal => (
          <DealCard deal={deal} key={deal.id} />
        ))}
      </div>
    </div>
  );
};

export default DealsList;
