import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <h1>Privacy Policy</h1>
      <p>Read our privacy policy.</p>
    </div>
  );
};

export default PrivacyPolicy;
