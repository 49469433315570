import React from 'react';

const Pagination = ({ page, totalPages, onPageChange }) => {
  const handlePrevPage = () => {
    if (page > 1) onPageChange(page - 1);
  };

  const handleNextPage = () => {
    if (page < totalPages) onPageChange(page + 1);
  };

  return (
    <div className="pagination">
      <button onClick={handlePrevPage} disabled={page === 1}>
        &laquo; Previous
      </button>
      <span>
        Page {page} of {totalPages}
      </span>
      <button onClick={handleNextPage} disabled={page === totalPages}>
        Next &raquo;
      </button>
    </div>
  );
};

export default Pagination;
