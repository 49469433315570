import React, { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "./Pagination";
import "../App.css";
import DealsList from "./DealsList";
import Loader from "./Loader";

const Home = () => {
  const [deals, setDeals] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDeals(page);
  }, [page]);

  const fetchDeals = async (page) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://server.updeals.in/deals?page=${page}&limit=40`
      );
      setLoading(false);
      setDeals(response.data.deals);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching deals:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="app">
      {loading ? (
       <Loader/>
      ) : (
        <>
          <DealsList deals={deals} />
          <Pagination
            page={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default Home;
