import React from "react";
import "./Footer.css"; 

const Footer = () => {
  return (
    <footer className="footer">
      <p>
        &copy; {new Date().getFullYear()} UpDeals,{" "}
        <a href="https://upshrink.com" rel="noreferrer" target="_blank" style={{textDecoration:"none"}}>
          UpShrink
        </a>{" "}
        All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
