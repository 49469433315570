// src/components/DealCard.js
import React from 'react';

const DealCard = ({ deal }) => {
  return (
    <div className={`deal-card ${deal.type === 'image' ? 'image-deal-card' : 'text-deal-card'}`}>
      {deal.type === 'image' && (
        <img src={deal.image_url} alt={deal.content} className="deal-image" />
      )}
      <div className="deal-content">
        {deal.content.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line.includes('http') ? (
              <a href={line} target="_blank" rel="noopener noreferrer">{line}</a>
            ) : (
              <p>{line}</p>
            )}
          </React.Fragment>
        ))}
      </div>
      <small>{new Date(deal.created_at).toLocaleString()}</small>
    </div>
  );
};

export default DealCard;
